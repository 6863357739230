<template>
  <div>
    <div class="header">
      <van-nav-bar :title="$t('hq.title')"> </van-nav-bar>
    </div>
    <div class="maincontent">
      <van-tabs color="#004ea3" @click="change" v-model="activeindex">
        <!-- 新闻快讯 -->
        <van-tab :title="$t('hq.news')">
          <van-row>
            <van-row
              class="publish"
              v-for="item in newslist"
              :key="item.id"
              @click="detail(item.id)"
            >
              <van-col :span="10" style="padding: 10px; height: 100%;">
                <van-image style="width:100%;height:100%" :src="item.image" />
              </van-col>
              <van-col
                :span="14"
                style="padding: 15px; text-align: left; height: 100%;"
              >
                <van-row class="publish-msg">
                  <van-row>
                    <span>{{ item.title }}</span>
                  </van-row>
                </van-row>
                <van-row
                  style="color: rgb(178, 178, 178); margin-top: 8px; font-size: 14px; text-align: right;"
                >
                  <span>{{ item.createtime | dateformat }}</span>
                </van-row>
              </van-col>
            </van-row>
          </van-row>
        </van-tab>
        <!-- 市场 -->
        <van-tab :title="$t('hq.market')">
          <van-tabs color="#004ea3" v-model="active2">
            <van-tab :title="$t('indexmenu.合约')">
              <van-row class="currency-title">
                <van-col span="8"
                  ><span>{{ $t('hq.hqname') }}</span></van-col
                >
                <van-col span="8"
                  ><span>{{ $t('hq.price') }}</span></van-col
                >
                <van-col span="8"
                  ><span>{{ $t('hq.updown') }}</span></van-col
                >
              </van-row>
              <van-row
                class="currency-Increase"
                v-for="item in productlist"
                :key="item.id"
              >
                <van-col
                  span="10"
                  style="display: flex;align-items: center;justify-content: flex-start;padding-left: 10px;"
                >
                <van-image
                        round
                        width="40px"
                        height="40px"
                        :src="baseURl + item.img"
                      />
                  <span style="font-size: 13px;padding-left: 10px;">{{
                    item.product_title
                  }}</span></van-col
                >
                <van-col span="6"
                  ><span style="color:black"
                    ><b>{{ item.current }}</b></span
                  ></van-col
                >
                <van-col span="8"
                  ><van-button
                    class="currency-btn"
                    :style="{
                      backgroundColor: item.change > 0 ? '#85B480' : '#B34F59'
                    }"
                    @click="totrade(item.pid)"
                  >
                    {{ item.change }}%
                  </van-button></van-col
                >
              </van-row>

              <van-row style="margin:20px" class="van-empty" v-if="!productlist.length">
                <div class="van-empty__image">
                  <img src="https://img01.yzcdn.cn/vant/empty-image-default.png">
                </div>
                <span>{{ $t('common.noproduct') }}</span>
              </van-row>
            </van-tab>
            <van-tab :title="$t('trade.title3')">
              <van-row class="currency-title">
                <van-col span="8"
                  ><span>{{ $t('hq.hqname') }}</span></van-col
                >
                <van-col span="8"
                  ><span>{{ $t('hq.price') }}</span></van-col
                >
                <van-col span="8"
                  ><span>{{ $t('hq.updown') }}</span></van-col
                >
              </van-row>
              <van-row
                class="currency-Increase"
                v-for="item in productlist2"
                :key="item.id"
              >
                <van-col span="8"
                  ><span>{{ item.product_title }}</span></van-col
                >
                <van-col span="8"
                  ><span style="color:black"
                    ><b>{{ item.current }}</b></span
                  ></van-col
                >
                <van-col span="8"
                  ><van-button
                    class="currency-btn"
                    :style="{
                      backgroundColor: item.change > 0 ? '#85B480' : '#B34F59'
                    }"
                    @click="totrade(item.pid)"
                  >
                    {{ item.change }}%
                  </van-button></van-col
                >
              </van-row>

              <van-row style="margin:20px" class="van-empty" v-if="!productlist2.length">
                <div class="van-empty__image">
                  <img src="https://img01.yzcdn.cn/vant/empty-image-default.png">
                </div>
                <span>{{ $t('common.noproduct') }}</span>
              </van-row>
            </van-tab>
          </van-tabs>
        </van-tab>
        <!-- 自选 -->
        <van-tab :title="$t('hq.zixuan')">
          <van-row class="currency-title">
            <van-col span="8"
              ><span>{{ $t('hq.hqname') }}</span></van-col
            >
            <van-col span="8"
              ><span>{{ $t('hq.price') }}</span></van-col
            >
            <van-col span="8"
              ><span>{{ $t('hq.updown') }}</span></van-col
            >
          </van-row>
          <van-row
            class="currency-Increase"
            v-for="item in zxlist"
            :key="item.id"
          >
            <van-col span="8"
              ><span>{{ item.product_title }}</span></van-col
            >
            <van-col span="8"
              ><span style="color:black"
                ><b>{{ item.current }}</b></span
              ></van-col
            >
            <van-col span="8"
              ><van-button
                class="currency-btn"
                :style="{
                  backgroundColor: item.change > 0 ? '#85B480' : '#B34F59'
                }"
                @click="totrade(item.productid)"
              >
                {{ item.change }}%
              </van-button></van-col
            >
          </van-row>
          <van-row class="click-add" @click="add">
            <van-icon name="plus" style="font-size: 2rem;" />
            <van-row>
              <span style="color: rgb(198, 197, 197);">{{
                $t('hq.addbtn')
              }}</span>
            </van-row>
          </van-row>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

      baseURl: 'https://manages.web3km.com',
      newslist: [],
      product:[],
      productlist: [],
      productlist2: [],
      zxlist: [],
      activeindex: 1,
      active2:0,
      wk: null
    }
  },
  created() {
    this.active2 = (this.$route.params.active?1:0);
    this.getproduct()
    this.initwk()
  },
  methods: {
    async getnews() {
      const { data } = await this.$http.get('/home/news/newslist')
      if (data) {
        if (data.code === 200) {
          this.newslist = data.data
        }
      }
    },
    async getproduct() {
      const { data } = await this.$http.get('/home/news/productlist2')
      if (data) {
        if (data.code === 200) {
          this.productlist = data.data.product
          this.productlist2 = data.data.product2
        }
      }
    },
    async getzxlist() {
      const { data } = await this.$http.get('/home/news/zxlist')
      if (data) {
        if (data.code === 200) {
          this.zxlist = data.data
        }
      }
    },
    // 新闻详情
    detail(id) {
      this.$router.push({ path: '/news/detail/' + id })
    },
    // 选项卡事件
    change(id) {
      if (id === 0) {
        this.getnews()
      }
      if (id === 1) {
        this.getproduct()
      }
      if (id === 2) {

	  // 获取token
	  const tokenstr = window.localStorage.getItem('token')
	  if (!tokenstr) {
		this.$router.push({ path: '/login?redirect=news' })
	  }else{
		  this.getzxlist()
	  }

      }
    },
    // 添加自选
    add() {
      this.$router.push('/trade')
    },
    // 去交易页
    totrade(id) {
      this.$router.push('/trade_' + id)
    },
    initwk() {
      this.wk = new WebSocket(this.wsurl + '/wssss:8080')
      this.wk.onmessage = this.wkonmessage
    },
    wkonmessage(e) {
      this.product = JSON.parse(e.data)
      const data = JSON.parse(e.data)
      var data2 = new Array;
      var data3 = new Array;
      this.zxlist.map(item => {
        this.product.forEach(p => {
          if (p.product_title === item.product_title) {
            item.change = p.change
            item.current = p.current
          }
        })
      })


      for (let key in data) {
        if(data[key].product_type_id==1 || data[key].product_type_id==2){
           data2.push(data[key]);
        }
        if(data[key].product_type_id==3){
             data3.push(data[key]);
        }
      }

      this.productlist = data2
      this.productlist2 = data3
    }
  }
}
</script>

<style lang="less" scoped>
*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
}
.header {
  width: 100%;
}
.maincontent {
  padding: 50px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  /deep/.van-tabs__nav--line {
    box-sizing: initial;
    height: 100%;
    padding-bottom: 15px;
  }
  /deep/.van-tab {
    font-size: 1rem;
  }
  /deep/.van-tab--active {
    color: #004ea3;
  }
  // 新闻快讯
  .publish {
    height: 7.2rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    border-bottom: 0.02667rem solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-content: center;
    .publish-msg {
      height: 80%;
      color: #000;
      font-size: 0.8rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      span {
        line-height: 1.25rem;
      }
    }
  }
  // 自选  // 市场
  .currency-title {
    margin-top: 0.5rem;
    margin-bottom: 0.4rem;
    font-size: 0.8rem;
    color: #b2b2b2;
    span {
      font-size: 0.875rem;
    }
  }
  .currency-Increase {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    font-size: 1.12rem;
    display: flex;
    align-items: center;
    line-height: 1.33333rem;
    border-bottom: 0.02667rem solid #e5e5e5;
    .currency-btn {
      border: none;
      border-radius: 0.26667rem;
      width: 6.4rem;
      height: 2.13333rem;
      color: #fff;
      font-size: 1.15rem;
    }
  }
  // 添加按钮
  .click-add {
    padding-top: 1.5rem;
  }
}
</style>
